import React from "react"
import PropTypes from "prop-types"
import { Button, Col, Form, Input, Row, Table } from "antd"
import { useIntl } from "gatsby-plugin-intl"

import { checkPermissions } from "../services/auth/permissions/permissions"
import useStateObject from "../hooks/useStateObject"
import deleteIcon from "../assets/images/ingests/ic-delete-on.png"

const { Search } = Input

const ListContextItem = ({ items, onRowDelete, entity, columnsDefinition }) => {
  const intl = useIntl()

  const [state, setState] = useStateObject({
    filter: "",
  })

  const getDataSource = () => {
    if (!Array.isArray(items)) {
      return null
    } else {
      if (state.filter) {
        return items.filter(item =>
          item.id.toLowerCase().includes(state.filter.toLowerCase())
        )
      } else {
        return items.slice()
      }
    }
  }

  const setListFilter = e => {
    setState({ filter: e.currentTarget.value })
  }

  const handleDelete = record => {
    onRowDelete(record.id)
  }

  const getColumnDefinition = column => {
    if (columnsDefinition && columnsDefinition[column]) {
      const { title } = columnsDefinition[column]
      return {
        title: title || intl.formatMessage({ id: column }),
      }
    }
    return {
      title: intl.formatMessage({ id: column }),
    }
  }

  const columns = [
    {
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => (a.id && b.id ? a.id.localeCompare(b.id) : 0),
      sortDirections: ["ascend", "descend"],
      ellipsis: true,
      ...getColumnDefinition("code"),
    },
    {
      title: intl.formatMessage({ id: "description" }),
      dataIndex: "desc",
      key: "desc",
      sorter: (a, b) => (a.desc && b.desc ? a.desc.localeCompare(b.desc) : 0),
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: "ascend",
      ellipsis: true,
    },
  ]
  if (checkPermissions([`${entity.id}:delete`])) {
    columns.push({
      title: intl.formatMessage({ id: "actions" }),
      dataIndex: "actions",
      width: 120,
      align: "center",
      render: (text, record) => (
        <div>
          {checkPermissions([`${entity.id}:delete`]) ? (
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={e => handleDelete(record, e)}
              loading={record.deleting}
            >
              <img
                style={{ fontSize: "1.25rem" }}
                className="ingest-actions__button__delete"
                src={deleteIcon}
                alt="deleteIcon"
              />
            </Button>
          ) : null}
        </div>
      ),
    })
  }

  const dataSource = getDataSource()
  const loading = !Array.isArray(dataSource)

  return (
    <div className="content">
      <Row type="flex" justify="center" align="top">
        <Col span={12} pull={6}>
          <Form.Item>
            <Search
              style={{ height: "40px" }}
              placeholder={`${intl.formatMessage({
                id: "filter-by",
              })} ${intl.formatMessage({ id: "name" })}`}
              onInput={setListFilter}
              disabled={loading}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Table
            className="ingests-table"
            size="small"
            rowKey="id"
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
            locale={{
              filterTitle: intl.formatMessage({ id: "filters" }),
              filterConfirm: intl.formatMessage({ id: "apply" }),
              filterReset: intl.formatMessage({ id: "reset" }),
              emptyText: intl.formatMessage({ id: "no-data" }),
            }}
          />
        </Col>
      </Row>
    </div>
  )
}

ListContextItem.propTypes = {
  entity: PropTypes.object.isRequired,
  items: PropTypes.array,
  onRowDelete: PropTypes.func.isRequired,
  columnsDefinition: PropTypes.object,
}

ListContextItem.defaultProps = {
  items: null,
}

export default ListContextItem
