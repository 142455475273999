import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import sourceUtils from "../../utils/context/source-utils"
import ContextCommonPage from "../../components/context"

const SourcePage = () => {
  const intl = useIntl()
  const { entity, formFields } = sourceUtils.getProps({ intl })

  return (
    <ContextCommonPage
      entity={entity}
      formFields={formFields}
      prop="sources"
      formTitle={intl.formatMessage({ id: "create-new-source" })}
    />
  )
}

export default SourcePage
