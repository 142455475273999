import fetchUtils from "../fetch-utils"
import { message, Modal } from "antd"

const updateDeleting = ({
  state,
  setState,
  deleting,
  id,
  prop,
  stateProp = null,
}) => {
  const items = state[stateProp || prop].slice()
  const item = items.find(s => s.id === id)
  item.deleting = deleting
  setState({ [stateProp || prop]: items })
}

const refreshTable = async ({ setState, intl, prop, stateProp = null }) => {
  try {
    const items = await fetchUtils.authFetchJson(
      `${process.env.GATSBY_CONF_API_URL}/${prop}`
    )
    setState({ [stateProp || prop]: items })
  } catch (e) {
    setState({ [stateProp || prop]: [] })
    message.error(intl.formatMessage({ id: "error-fetching-data" }))
  }
}

const onRowDelete = ({
  intl,
  state,
  setState,
  prop,
  stateProp = null,
}) => id => {
  Modal.confirm({
    title: intl.formatMessage({ id: "modal-are-you-sure" }),
    content: intl.formatMessage({
      id: "page-contexts-contexts-list-modal-content",
    }),
    okText: intl.formatMessage({ id: "modal-ok" }),
    okType: "danger",
    cancelText: intl.formatMessage({ id: "modal-ko" }),
    onOk: async () => {
      updateDeleting({ id, state, setState, deleting: true, prop, stateProp })
      try {
        await fetchUtils.authFetchJson(
          `${process.env.GATSBY_CONF_API_URL}/${prop}/${id}`,
          {
            method: "DELETE",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        await refreshTable({ setState, intl, prop, stateProp })
      } catch (e) {
        updateDeleting({
          id,
          state,
          setState,
          deleting: false,
          prop,
          stateProp,
        })
        message.error(intl.formatMessage({ id: "error-deleting-data" }))
      }
    },
  })
}

export default {
  updateDeleting,
  refreshTable,
  onRowDelete,
}
