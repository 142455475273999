const getProps = ({ intl }) => ({
  entity: {
    name: "Source",
    id: "source",
    menuId: "context-source-index",
    uri: "/sources",
    translate: "models-sources",
    label: intl.formatMessage({
      id: "models-source",
    }),
  },
  formFields: [
    {
      name: "id",
      type: "text",
      placeholder: intl.formatMessage({
        id: "page-contexts-sources-code-placeholder",
      }),
      rules: [
        {
          required: true,
          message: intl.formatMessage({
            id: "page-contexts-sources-code-missing",
          }),
        },
      ],
    },
    {
      name: "desc",
      type: "text",
      placeholder: intl.formatMessage({ id: "description" }),
      rules: [
        {
          required: true,
          message: intl.formatMessage({
            id: "page-contexts-sources-description-missing",
          }),
        },
      ],
    },
  ],
})

const sourceUtils = {
  getProps,
}

export default sourceUtils
