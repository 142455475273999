import React, { useEffect } from "react"
import { Col, Row } from "antd"
import { useIntl } from "gatsby-plugin-intl"

import Template from "../../layouts/base"
import withAuth from "../../components/withAuthHoc"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import WrappedFormNewContextItem from "../../components/contextNew"
import ListContextItem from "../../components/contextList"
import useStateObject from "../../hooks/useStateObject"
import utils from "../../utils/context/utils"
import PropTypes from "prop-types"

const ContextCommonPage = ({
  prop,
  entity,
  formFields,
  stateProp,
  columnsDefinition,
  formTitle,
}) => {
  const intl = useIntl()

  const [state, setState] = useStateObject({
    [prop]: null,
  })

  useEffect(() => {
    ;(async () => {
      await utils.refreshTable({ setState, intl, prop, stateProp })
    })()
  }, [])

  return (
    <Template selected={["context", entity.menuId]}>
      <CustomBreadcrumb
        crumbs={[
          `${intl.formatMessage({ id: "manage-of" })} ${intl.formatMessage({
            id: `models-${prop}`,
          })}`,
        ]}
      />
      <Row>
        <Col className="intro">
          <h1>
            {intl.formatMessage({ id: "list-of" })}{" "}
            {intl.formatMessage({ id: `models-${prop}` })}
          </h1>
        </Col>
      </Row>
      <ListContextItem
        entity={entity}
        items={state[stateProp || prop]}
        onRowDelete={utils.onRowDelete({
          intl,
          state,
          setState,
          prop,
          stateProp,
        })}
        columnsDefinition={columnsDefinition}
      />
      <WrappedFormNewContextItem
        entity={entity}
        formFields={formFields}
        onContextItemAdd={() =>
          utils.refreshTable({ setState, intl, prop, stateProp })
        }
        title={formTitle}
      />
    </Template>
  )
}

ContextCommonPage.defaultProps = {
  stateProp: null,
}

ContextCommonPage.propTypes = {
  prop: PropTypes.string.isRequired,
  stateProp: PropTypes.string,
  entity: PropTypes.object.isRequired,
  formFields: PropTypes.array.isRequired,
  columnsDefinition: PropTypes.object,
  formTitle: PropTypes.string,
}

export default withAuth(ContextCommonPage)
