import React from "react"
import PropTypes from "prop-types"
import { Button, Col, Form, Input, message, Row, Select } from "antd"
import { useIntl } from "gatsby-plugin-intl"

import authFetch from "../services/network"
import { checkPermissions } from "../services/auth/permissions/permissions"
import useStateObject from "../hooks/useStateObject"

const { Option } = Select

const newContextItem = ({
  form,
  onContextItemAdd,
  formFields,
  entity,
  title,
}) => {
  const intl = useIntl()

  const [state, setState] = useStateObject({
    iconLoading: false,
  })

  if (!checkPermissions([`${entity.id}:write`])) {
    return null
  }

  /**
   * Wrapper to use fetch accross the page
   *
   * @param uri
   * @param obj
   * @returns {Promise<Response>}
   */
  const grabData = (uri, obj = null) => {
    return authFetch(uri, obj)
  }

  const resetForm = () => {
    form.resetFields()
  }

  /**
   * Method to handle the event change through the UI to save at state
   * @param event
   */
  const handleChange = event => {
    const {
      target: { name: n, value: v },
    } = event
    setState({ [n]: v })
  }

  /**
   * Method to handle the autocomplete event changes through the UI to save at state
   * @param name
   * @param value
   */
  const handleAutocompleteSelect = (name, value) => {
    setState({ [name]: value })
  }

  /**
   * Method to handle the form submit and save new entities
   * @param e
   */
  const handleSubmit = e => {
    // Prepare UI
    e.preventDefault()
    setState({ iconLoading: true })
    const body = {}
    formFields.forEach(field => (body[field.name] = state[field.name]))
    // Prepare Payload
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
    // Validate & submit
    form.validateFields(err => {
      if (err) {
        message.error(intl.formatMessage({ id: "check-fields-with-error" }))
        setState({ iconLoading: false })
      } else {
        grabData(`${process.env.GATSBY_CONF_API_URL}${entity.uri}/`, options)
          .then(callbackSaveOk)
          .then(onContextItemAdd)
      }
    })
  }

  /**
   * Handle the response and update the UI
   * @param data
   */
  const callbackSaveOk = data => {
    if (201 !== data.status) {
      message.error(intl.formatMessage({ id: "unable-to-save" }))
    } else {
      message.success(intl.formatMessage({ id: "save-field-success" }))
    }
    setState({ iconLoading: false })
    resetForm()
  }

  const { getFieldDecorator, getFieldError } = form

  return (
    <div className="content">
      <Row type="flex" justify="center" align="top">
        <Col span={24}>
          <h2>
            {title ||
              `${intl.formatMessage({ id: "add-new-x" })} ${entity.label}`}
          </h2>
          <Form layout="inline" onSubmit={handleSubmit}>
            {formFields.map(field => {
              const fieldError = getFieldError(field.name)
              return field.dataSource instanceof Array &&
                field.dataSource.length > 0 ? (
                <Form.Item
                  validateStatus={fieldError ? "error" : ""}
                  help={fieldError || ""}
                  key={field.name}
                >
                  {getFieldDecorator(field.name, {
                    initialValue: field.initialValue || "",
                    rules: field.rules,
                  })(
                    <Select
                      showSearch={true}
                      style={{ width: "200px" }}
                      name={field.name}
                      type={field.type}
                      filterOption={(inputValue, option) =>
                        option.props.children
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                      onSelect={(value, option) =>
                        handleAutocompleteSelect(field.name, value, option)
                      }
                    >
                      {field.dataSource.map(d => (
                        <Option key={d.value} value={d.value}>
                          {d.text}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              ) : (
                <Form.Item
                  validateStatus={fieldError ? "error" : ""}
                  help={fieldError || ""}
                  key={field.name}
                >
                  {getFieldDecorator(field.name, {
                    initialValue: field.initialValue || "",
                    rules: field.rules,
                  })(
                    <Input
                      placeholder={field.placeholder}
                      name={field.name}
                      type={field.type}
                      onChange={handleChange}
                    />
                  )}
                </Form.Item>
              )
            })}
            <Form.Item>
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={state.iconLoading}
              >
                {intl.formatMessage({ id: "add" })}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

newContextItem.propTypes = {
  entity: PropTypes.object.isRequired,
  formFields: PropTypes.array.isRequired,
  onContextItemAdd: PropTypes.func.isRequired,
  title: PropTypes.string,
}

export default Form.create({ name: "horizontal_login" })(newContextItem)
